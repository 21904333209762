<template>
    <div class="area_body">
        <div class="dashboard">
            <div class="recovery_page" :class="{'no_cover' : !memberSetting.display_card_image && memberSetting.display_card_icon}">
                <img :src="memberSetting.card_image ? memberSetting.card_image : require('@/assets/images/thumb/default-cover.svg')" alt="" class="banner" v-if="memberSetting.display_card_image">
                <div class="content_body">
                    <img :src="memberSetting.card_icon ? memberSetting.card_icon : require('@/assets/images/thumb/default-cover-square.svg')" alt="" class="icon" v-if="memberSetting.display_card_icon">
                    <h3 class="heading" v-html="memberSetting.parse_card_title"></h3>
                    <div class="setting_wpr mt-5">
                        <Form @submit="handleSubmit" v-slot="{ errors }" class="setting-form">
                            <div class="form_grp mt-2" v-if="optin_fields.first_name || optin_fields.last_name">
                                <div class="group_item" v-if="optin_fields.first_name">
                                    <label class="input_label">First Name</label>
                                    <div class="field_wpr" :class="{'has-error': errors.first_name}">
                                        <Field autocomplete="off" type="text" name="first_name" v-model="form.first_name" label="first name" placeholder="John" rules="required" />
                                    </div>
                                    <ErrorMessage name="first_name" class="text-danger" />
                                </div>
                                <div class="group_item" v-if="optin_fields.last_name">
                                    <label class="input_label">Last Name</label>
                                    <div class="field_wpr" :class="{'has-error': errors.last_name}">
                                        <Field autocomplete="off" type="text" name="last_name" v-model="form.last_name" label="last name" placeholder="Doe" rules="required" />
                                    </div>
                                    <ErrorMessage name="last_name" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mt-2" v-if="optin_fields.email">
                                <div class="group_item">
                                    <label class="input_label">E-mail</label>
                                    <div class="field_wpr has_prefix" :class="{'has-error': errors.email}">
                                        <Field autocomplete="off" type="email" name="email" v-model="form.email" placeholder="john.doe@onboardme.io" rules="required|email" />
                                        <span class="prefix"><i class="fas fa-envelope"></i></span>
                                    </div>
                                    <ErrorMessage name="email" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mt-2" v-if="optin_fields.phone || optin_fields.address">
                                <div class="group_item" v-if="optin_fields.phone">
                                    <label class="input_label">Phone Number</label>
                                    <div class="field_wpr has_prefix" :class="{'has-error': errors.phone}">
                                        <Field autocomplete="off" type="text" name="phone" v-model="form.phone" rules="required" placeholder="+1XXXXXX6789" />
                                        <span class="prefix"><i class="fas fa-phone-alt"></i></span>
                                    </div>
                                    <ErrorMessage name="phone" class="text-danger" />
                                </div>
                                <div class="group_item" v-if="optin_fields.address">
                                    <label class="input_label">Address</label>
                                    <div class="field_wpr" :class="{'has-error': errors.address}">
                                        <Field autocomplete="off" type="text" name="address" v-model="form.address" placeholder="Enter Address Here..." rules="required" />
                                    </div>
                                    <ErrorMessage name="address" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mt-2" v-if="optin_fields.city || optin_fields.state">
                                <div class="group_item" v-if="optin_fields.city">
                                    <label class="input_label">City</label>
                                    <div class="field_wpr" :class="{'has-error': errors.city}">
                                        <Field autocomplete="off" type="text" name="city" v-model="form.city" rules="required" placeholder="Enter City Here..." />
                                    </div>
                                    <ErrorMessage name="city" class="text-danger" />
                                </div>
                                <div class="group_item" v-if="optin_fields.state">
                                    <label class="input_label">State</label>
                                    <div class="field_wpr" :class="{'has-error': errors.state}">
                                        <Field autocomplete="off" type="text" name="state" v-model="form.state" placeholder="Enter Address Here..." rules="required" />
                                    </div>
                                    <ErrorMessage name="state" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mt-2" v-if="optin_fields.zip || optin_fields.dob">
                                <div class="group_item" v-if="optin_fields.zip">
                                    <label class="input_label">Postal Code</label>
                                    <div class="field_wpr" :class="{'has-error': errors.zip_code}">
                                        <Field autocomplete="off" type="text" name="zip_code" v-model="form.zip_code" rules="required" label="postal code" placeholder="Enter Postal Code Here..." />
                                    </div>
                                    <ErrorMessage name="zip_code" class="text-danger" />
                                </div>
                                <div class="group_item" v-if="optin_fields.dob">
                                    <label class="input_label">DOB</label>
                                    <div class="field_wpr" :class="{'has-error': errors.dob}">
                                        <Field autocomplete="off" name="dob" v-model="form.dob">
                                            <datepicker v-model="form.dob" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="dob" class="text-danger" />
                                </div>
                            </div>
                            <button class="primary_btn mt-5" :disable="loader" :style="`background-color: ${memberSetting.btn_color}; color: ${memberSetting.text_color}`">
                                <i class="fa fa-spin fa-spinner" v-if="loader"></i>&nbsp;{{ loader ? ' Sending' : memberSetting.btn_text }}
                            </button>
                        </Form>
                        <p class="privacy" v-if="memberSetting.is_privacy">{{memberSetting.privacy_text}} <a :href="memberSetting.privacy_url">Privacy Policy</a></p>
                        <p class="copyright" v-html="memberSetting.white_label"></p>
                        <!-- <p class="copyright">© Thrive Coach 2022. All rights reserved</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Member ID Public Dashboard',

        data () {
            return {
                form: {
                    type: 'full',
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    dob: '',
                },
                loader: false,
                optin_fields: {
                    first_name: 1,
                    last_name: 1,
                    email: 1,
                    phone: 1,
                    address: 0,
                    city: 0,
                    state: 0,
                    zip: 0,
                    dob: 0,
                },
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            memberSetting (memberSetting) {
                const vm = this;

                vm.optin_fields = JSON.parse(memberSetting.optin_fields);

                if (!vm.optin_fields || (vm.optin_fields && vm.optin_fields.first_name == undefined)) {
                    vm.optin_fields = {
                        first_name: 1,
                        last_name: 1,
                        email: 1,
                        phone: 1,
                        address: 0,
                        city: 0,
                        state: 0,
                        zip: 0,
                        dob: 0,
                    };
                }
            },
        },
        computed: mapState({
            memberSetting: state => state.memberIdModule.publicMemberSetting,
        }),

        mounted () {
            const vm = this;

            vm.getMemberSettingPublic(vm.$route.params);

            const parentEl = document.querySelector('.main_body.def');

            if (parentEl) {
                parentEl.style.height = 'auto';
            }
        },

        methods: {
            ...mapActions({
                getMemberSettingPublic: 'memberIdModule/getMemberSettingPublic',
                sendMemberId: 'memberIdModule/sendMemberId',
            }),

            handleSubmit (form, { setFieldError, resetForm }) {
                const vm = this;
                const params = { ...vm.form, ...vm.$route.params };
                      params.name = params.first_name + ' ' + params.last_name;

                vm.loader = true;

                vm.sendMemberId(params).then((result) => {
                    if (result) {
                        vm.resetForm();
                        resetForm();
                        const options = Helper.swalWarningOptions('Thank you!', 'Check your email or your phone for your new member id.');

                        Swal.fire(options)
                    }

                    vm.loader = false;
                });
            },

            resetForm () {
                const vm  = this;

                vm.form = {
                            type: 'full',
                            first_name: '',
                            last_name: '',
                            email: '',
                            phone: '',
                            address: '',
                            city: '',
                            state: '',
                            zip: '',
                            dob: '',
                        };

                vm.optin_fields = {
                                     first_name: 1,
                                     last_name: 1,
                                     email: 1,
                                     phone: 1,
                                     address: 0,
                                     city: 0,
                                     state: 0,
                                     zip: 0,
                                     dob: 0,
                                  };
            },
        }
    };
</script>

<style scoped>
    #app .main_body {
        margin: 0px !important;
    }

    .area_body {
        height: auto;
    }

    .area_body .dashboard {
        align-items: center;
        background: #fafafb;
        overflow: hidden;
        margin-bottom: 50px;
    }

    .recovery_page {
        border: 1px solid #eee;
        border-radius: 12px;
        align-items: center;
        background: #fff;
        margin: 40px 20px;
        line-height: 0;
        max-width: 800px;
        width: calc(100% - 40px);
    }

    .recovery_page .banner {
        /* height: 220px; */
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 12px 12px 0 0;
    }

    .recovery_page .content_body {
        padding: 25px 40px 40px 40px;
    }

    .recovery_page h3 {
        font-size: 20px;
        line-height: 28px;
        color: #121525;
        font-weight: 400;
        padding-bottom: 10px;
        text-align: center;
        max-width: 500px;
        margin: 0 auto;
    }

    .recovery_page .icon {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        position: relative;
        display: block;
        margin: -75px auto 20px auto;
        border: 3px solid #fff;
    }

    .recovery_page.no_cover {
        margin-top: 70px;
    }

    .recovery_page h5 {
        font-size: 15px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
        text-align: center;
    }

    .recovery_page .checkbox {
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 18px;
        margin: 25px 0;
    }

    .recovery_page .privacy {
        font-size: 14px;
        line-height: 18px;
        color: #666;
        font-weight: 400;
        text-align: center;
        padding: 20px 0 10px;
    }

    .recovery_page .privacy a {
        color: #2f7eed;
        text-decoration: none;
    }

    .recovery_page .copyright {
        font-size: 14px;
        line-height: 18px;
        color: #666;
        font-weight: 400;
        text-align: center;
        padding: 10px 0 0;
    }
    :deep(.recovery_page .copyright a) {
        font-size: 14px;
        line-height: 18px;
        color: #666;
        font-weight: 400;
        text-align: center;
        padding: 10px 0 0;
        text-decoration: none;
    }

    .setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .group_item {
        text-align: left;
    }

    .field_wpr.has-error {
        margin-bottom: 10px;
    }

    @media(max-width: 425px){
        .setting_wpr .group_item {
            flex: 1 0 100%;
        }

        .setting_wpr .group_item + .group_item {
            margin-top: 30px;
        }

        .setting_wpr .form_grp.mt-2 {
            margin-top: 0 !important;
        }
    }
</style>
